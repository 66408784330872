'use strict';

angular.module('enervexSalesappApp').controller('CustomerContactsCtrl', function($scope, $http, Auth, CustomerContact, CustomerCompany, $stateParams, _) {
	$scope.allUsers = [];
	$scope.offset = 0;
	$scope.limit = 100;

	$scope.companies = [];
	$scope.offsetCompanies = 0;
	$scope.limitCompanies = 100;

	function fetchContacts() {
		CustomerContact.query({
			offset:$scope.offset,
			limit: $scope.limit,
			repco: $stateParams.companyId
		}).$promise.then(function(users){
			users = _.map(users, function(user){
				user.company = $scope.company
				return user;
			})
			if (users.length > 0) {
				$scope.allUsers = $scope.allUsers.concat(users);
				$scope.offset = $scope.offset + $scope.limit;
				fetchContacts();
			} else {
				$scope.users = $scope.allUsers;
			}
		});
	}

	function fetchCompany() {
		CustomerCompany.get({
			id: $stateParams.companyId
		}).$promise.then(function(company) {
			$scope.company = company;
			fetchContacts()
		});
	}
	fetchCompany();
	$scope.selectFilter = function() {
		if (!$scope.filtered || $scope.filtered == "") {
			$scope.users = $scope.allUsers;
		} else {
			$scope.users = _.filter($scope.allUsers, function(user){
				return user.RepCo == $scope.filtered;
			})
			console.log("selected "+$scope.users.length)
		}
	}
	$scope.sortType = 'name';
	$scope.sortReverse = false;
});
